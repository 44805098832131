import React from "react";
import color1 from "../../../assets/termékek/szinek/fa szin/DSC0099.jpg";
import color2 from "../../../assets/termékek/szinek/fa szin/DSC0101.jpg";
import color3 from "../../../assets/termékek/szinek/fa szin/DSC0103.jpg";
import color4 from "../../../assets/termékek/szinek/fa szin/DSC0105.jpg";
import color5 from "../../../assets/termékek/szinek/muanyag szin/1.jpg";
import color6 from "../../../assets/termékek/szinek/muanyag szin/2.jpg";
import color7 from "../../../assets/termékek/szinek/muanyag szin/3.jpg";
import color8 from "../../../assets/termékek/szinek/muanyag szin/4.jpg";
import color9 from "../../../assets/termékek/szinek/muanyag szin/5.jpg";
import color10 from "../../../assets/termékek/szinek/muanyag szin/6.jpg";
import color11 from "../../../assets/termékek/szinek/muanyag szin/7.jpg";
import color12 from "../../../assets/termékek/szinek/muanyag szin/8.jpg";
import color13 from "../../../assets/termékek/szinek/muanyag szin/9.jpg";
import color14 from "../../../assets/termékek/szinek/muanyag szin/10.jpg";
import color15 from "../../../assets/termékek/szinek/muanyag szin/11.jpg";
import color16 from "../../../assets/termékek/szinek/muanyag szin/12.jpg";
import color17 from "../../../assets/termékek/szinek/muanyag szin/13.jpg";
import color18 from "../../../assets/termékek/szinek/muanyag szin/14.jpg";
import color19 from "../../../assets/termékek/szinek/muanyag szin/15.jpg";
import color20 from "../../../assets/termékek/szinek/muanyag szin/16.jpg";
import color21 from "../../../assets/termékek/szinek/muanyag szin/17.jpg";
import color22 from "../../../assets/termékek/szinek/muanyag szin/18.jpg";
import color23 from "../../../assets/termékek/szinek/muanyag szin/19.jpg";
import color24 from "../../../assets/termékek/szinek/muanyag szin/20.jpg";
import color25 from "../../../assets/termékek/szinek/muanyag szin/21.jpg";
import color26 from "../../../assets/termékek/szinek/muanyag szin/22.jpg";
import color27 from "../../../assets/termékek/szinek/muanyag szin/23.jpg";
import color28 from "../../../assets/termékek/szinek/muanyag szin/24.jpg";
import color29 from "../../../assets/termékek/szinek/muanyag szin/25.jpg";
import color30 from "../../../assets/termékek/szinek/muanyag szin/26.jpg";
import color31 from "../../../assets/termékek/szinek/muanyag szin/27.jpg";
import color32 from "../../../assets/termékek/szinek/muanyag szin/28.jpg";
import color33 from "../../../assets/termékek/szinek/muanyag szin/29.jpg";
import color34 from "../../../assets/termékek/szinek/muanyag szin/30.jpg";
import color35 from "../../../assets/termékek/szinek/muanyag szin/31.jpg";
import color36 from "../../../assets/termékek/szinek/muanyag szin/32.jpg";
import color37 from "../../../assets/termékek/szinek/muanyag szin/33.jpg";
import color38 from "../../../assets/termékek/szinek/muanyag szin/34.jpg";
import color39 from "../../../assets/termékek/szinek/muanyag szin/35.jpg";
import color40 from "../../../assets/termékek/szinek/muanyag szin/36.jpg";
import color41 from "../../../assets/termékek/szinek/muanyag szin/37.jpg";
import color42 from "../../../assets/termékek/szinek/muanyag szin/38.jpg";
import color43 from "../../../assets/termékek/szinek/muanyag szin/39.jpg";
import color44 from "../../../assets/termékek/szinek/muanyag szin/40.jpg";
import color45 from "../../../assets/termékek/szinek/muanyag szin/41.jpg";
import color46 from "../../../assets/termékek/szinek/muanyag szin/42.jpg";
import color47 from "../../../assets/termékek/szinek/muanyag szin/43.jpg";
import color48 from "../../../assets/termékek/szinek/muanyag szin/44.jpg";
import color49 from "../../../assets/termékek/szinek/muanyag szin/45.jpg";
import color50 from "../../../assets/termékek/szinek/muanyag szin/46.jpg";
import color51 from "../../../assets/termékek/szinek/muanyag szin/47.jpg";
import color52 from "../../../assets/termékek/szinek/muanyag szin/48.jpg";
import color53 from "../../../assets/termékek/szinek/muanyag szin/49.jpg";
import color54 from "../../../assets/termékek/szinek/muanyag szin/50.jpg";
import color55 from "../../../assets/termékek/szinek/muanyag szin/51.jpg";
import color56 from "../../../assets/termékek/szinek/muanyag szin/52.jpg";
import color57 from "../../../assets/termékek/szinek/muanyag szin/53.jpg";
import color58 from "../../../assets/termékek/szinek/muanyag szin/54.jpg";
import color59 from "../../../assets/termékek/szinek/muanyag szin/55.jpg";

export default function Color() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-center">Színek</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">FA SZÍNMINTÁK</h2>
          </div>
          <div class="row row-cols-2 row-cols-md-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
            <div class="col">
              <div class="card prod h-100">
                <img src={color1} class="card-img-top img-fluid p-2" />
                <div class="card-body">
                  <h5 class="card-title">RAL SZÍNEK</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod  h-100">
                <img src={color2} class="card-img-top img-fluid p-2" />
                <div class="card-body">
                  <h5 class="card-title">TREND lazúrrendszer</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={color3} class="card-img-top img-fluid p-2" />
                <div class="card-body">
                  <h5 class="card-title">Színezett lazúrrendszer</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={color4} class="card-img-top img-fluid p-2" />
                <div class="card-body">
                  <h5 class="card-title">Standard lazúrrendszer</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">MŰANYAG PANEL SZÍNTÁBÁZAT</h2>
          </div>
        </div>
        <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4  row-cols-xl-6 g-4 mb-5">
          <div class="col">
            <div class="card prod h-100">
              <img src={color5} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Winchester 49240</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color6} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Siena Rosso 49233</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color7} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Siena Noce 49237</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color8} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Eiche hell 3118076</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color9} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Bergkiefer 3069041</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color10} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Eiche rustikal 3156003</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color11} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Shogun AC 49197</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color12} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Shogun AF 49198</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color13} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Douglasie 3069037</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color14} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Shogun AD 49195</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color15} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Macore 3162002</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color16} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Mooreiche 2052089</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color17} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Mahagoni 2065021</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color18} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Rosewood SoFo02002</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color19} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Braun 887505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color20} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Nussbaum 2178007</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color21} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Golden oak 2178001</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color22} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Streifendouglasie 3152009</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color23} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Oregon III 2115008</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color24} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Cremeweiß 137905</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color25} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Weiß 915205</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color26} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Lichtgrau 725105</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color27} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Papyrusweiß 901805</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color28} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Aluminium gebürstet 4361001</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color29} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Zinkgelb 108705</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color30} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Hellelfenbein 101505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color31} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Brillantblau 500705</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color32} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Ultramarinkék 500205</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color33} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Kobaltblau 501305</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color34} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Stahlblau 515005</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color35} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Monumentenblau 500405</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color36} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Smaragdgrün 611005</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color37} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Moosgrün 600505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color38} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Kékeszöld 600405</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color39} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Monumentengrün 992505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color40} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Tannengrün 612505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color41} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Karminrot 305405</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color42} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Rubinrot 300305</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color43} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Weinrot 300505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color44} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Braunrot 308105</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color45} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Schwarzbraun 851805</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color46} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Quarzgrau 703905</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color47} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Quarz platin (metallic) 1293002</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color48} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Crown platin (metallic) 1293001</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color49} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Anthrazitgrau seidenglatt 4367003</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color50} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">
                  Silbergrau ungenarbt (glatt) 715505083
                </h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color51} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Silbergrau 715505</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color52} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Earl platin (metallic) 1293010</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color53} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">
                  Basaltgrau ungenarbt (glatt) 701205083
                </h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color54} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Basaltgrau 701205</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod h-100">
              <img src={color55} class="card-img-top img-fluid p-2" />
              <div class="card-body">
                <h5 class="card-title">Metbrush messing 4361014</h5>
              </div>
            </div>
          </div>
        <div class="col">
          <div class="card prod h-100">
            <img src={color56} class="card-img-top img-fluid p-2" />
            <div class="card-body">
              <h5 class="card-title">Schiefergrau ungenarbt (glatt) 701505083</h5>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card prod h-100">
            <img src={color57} class="card-img-top img-fluid p-2" />
            <div class="card-body">
              <h5 class="card-title">
              Schiefergrau 701505
              </h5>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card prod h-100">
            <img src={color58} class="card-img-top img-fluid p-2" />
            <div class="card-body">
              <h5 class="card-title">Achatgrau 703805</h5>
            </div>
          </div>
        </div>
        <div class="card prod h-100">
          <img src={color59} class="card-img-top img-fluid p-2" />
          <div class="card-body">
            <h5 class="card-title">Anthrazitgrau 701605</h5>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
