import React from "react";
import './global_style.css';
import Navbar from "./components/header/nav";
import Home from "./components/body/pages/page1";
import Services from "./components/body/pages/page2";
import Products from "./components/body/pages/page3";
import Contact from "./components/body/pages/page5";
import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Footer from "./components/footer/footer";
import Fixed_footer from "./components/footer/fixed_footer";
import Wooden from "./components/body/pages/wooden";
import Plastic from "./components/body/pages/plastic";
import Color from "./components/body/pages/color";
import Aszf from "./components/body/pages/aszf";
import ContactUs from './components/body/pages/priceform';
import References from "./components/body/pages/references";
import AboutUs from "./components/body/pages/page4";

export default function App(){  
  
   
    return (
        <>
        <Router>
            <Navbar/>
            <Routes>
            <Route path='/Home' element={<Home/>} />
            <Route path='/Services' element={<Services/>} />
            <Route path='/Products' element={<Products/>} />
            <Route path='/References' element={<References/>} />
            <Route path='/AboutUs' element={<AboutUs/>} />
            <Route path='/Contact' element={<Contact/>} />
            <Route path="/" element={<Navigate to="/Home" />} />
            <Route path='/Products/Wooden' element={<Wooden />} />
            <Route path='/Products/Plastic' element={<Plastic />} />
            <Route path='/Products/Color' element={<Color />} />
            <Route path='/ASZF' element={<Aszf />} />
            <Route path='/PriceEstimates' element={<ContactUs />} />
            </Routes>
            <Fixed_footer/>
            <Footer/>
        </Router>
       
        </>
    )
}