import React from "react";
import m_pic1 from "../../../assets/termékek/muanyag/VE-AB-1.jpg";
import m_pic2 from "../../../assets/termékek/muanyag/VE-AB-3.jpg";
import m_pic3 from "../../../assets/termékek/muanyag/LUG_PFM.jpg";
import m_pic4 from "../../../assets/termékek/muanyag/LUG_DPK.jpg";
import m_pic5 from "../../../assets/termékek/muanyag/LUG_PFB.jpg";
import m_pic6 from "../../../assets/termékek/muanyag/mikanovci2.jpg";
import m_pic7 from "../../../assets/termékek/muanyag/mikanovci4.jpg";
import m_pic8 from "../../../assets/termékek/muanyag/NIJEMCI_DPL.jpg";
import m_pic9 from "../../../assets/termékek/muanyag/vinkovci2.jpg";
import m_pic10 from "../../../assets/termékek/muanyag/vukovar2.jpg";
import m_pic11 from "../../../assets/termékek/muanyag/sara.jpg";
import m_pic12 from "../../../assets/termékek/muanyag/tara.jpg";
import m_pic13 from "../../../assets/termékek/muanyag/mara.jpg";
import m_pic14 from "../../../assets/termékek/muanyag/lara.jpg";
import m_pic15 from "../../../assets/termékek/muanyag/zora.jpg";
import m_pic16 from "../../../assets/termékek/muanyag/mara+.jpg";
import m_pic17 from "../../../assets/termékek/muanyag/premium-home.jpg";
import m_pic18 from "../../../assets/termékek/muanyag/MONET.jpg";
import m_pic19 from "../../../assets/termékek/muanyag/VERMEER_DPH.jpg";
import m_pic20 from "../../../assets/termékek/muanyag/BOTTICELLI_DPV.jpg";
import m_pic21 from "../../../assets/termékek/muanyag/DALI_DPZ.jpg";
import m_pic22 from "../../../assets/termékek/muanyag/RENOIR_PFL.jpg";
import m_pic23 from "../../../assets/termékek/muanyag/DEGAS_DPR.jpg";
import m_pic24 from "../../../assets/termékek/muanyag/ZUM-PJ.jpg";
import m_pic25 from "../../../assets/termékek/muanyag/biokovo5.jpg";
import m_pic26 from "../../../assets/termékek/muanyag/psunj2.jpg";
import m_pic27 from "../../../assets/termékek/muanyag/GAL-DPJ.jpg";
import m_pic28 from "../../../assets/termékek/muanyag/JAN-DPL.jpg";
import m_pic29 from "../../../assets/termékek/muanyag/smart1-7016.png";
import m_pic30 from "../../../assets/termékek/muanyag/smart3-5000.png";
import m_pic31 from "../../../assets/termékek/muanyag/smart5orah.png";
import m_pic32 from "../../../assets/termékek/muanyag/smart6-9016-1.png";
import m_pic33 from "../../../assets/termékek/muanyag/smart7-ga.png";
import m_pic34 from "../../../assets/termékek/muanyag/smart-JSK-10-9003.jpg";
import m_pic35 from "../../../assets/termékek/muanyag/Basic1-3d.png";
import m_pic36 from "../../../assets/termékek/muanyag/Basic3ga-3d.png";
import m_pic37 from "../../../assets/termékek/muanyag/Basic5-3d.png";
import m_pic38 from "../../../assets/termékek/muanyag/Basic7-3d.png";
import m_pic39 from "../../../assets/termékek/muanyag/Basic9_29-80077-3d.png";
import m_pic40 from "../../../assets/termékek/muanyag/Basic10-3d.png";
import m_pic41 from "../../../assets/termékek/muanyag/seine01.jpg";
import m_pic42 from "../../../assets/termékek/muanyag/loire01.jpg";
import m_pic43 from "../../../assets/termékek/muanyag/talinn2.jpg";
import m_pic44 from "../../../assets/termékek/muanyag/bologna05.jpg";
import m_pic45 from "../../../assets/termékek/muanyag/hamburg04.jpg";
import m_pic46 from "../../../assets/termékek/muanyag/milano05.jpg";
import m_pic47 from "../../../assets/termékek/muanyag/minsk3.jpg";
import m_pic48 from "../../../assets/termékek/muanyag/zuerich01.jpg";
import m_pic49 from "../../../assets/termékek/muanyag/napoli4.jpg";
import m_pic50 from "../../../assets/termékek/muanyag/Aalborg1-3.png";
import m_pic51 from "../../../assets/termékek/muanyag/Sion1.png";
import m_pic52 from "../../../assets/termékek/muanyag/fogantyu1.jpg";
import m_pic53 from "../../../assets/termékek/muanyag/foganytu_2.jpg";
import m_pic54 from "../../../assets/termékek/muanyag/kilincs1.jpg";
import m_pic55 from "../../../assets/termékek/muanyag/rozetta.jpg";
import m_pic56 from "../../../assets/termékek/muanyag/dos.png";
import m_pic57 from "../../../assets/termékek/muanyag/kcr.png";
import m_pic58 from "../../../assets/termékek/muanyag/ocr.png";
import m_pic59 from "../../../assets/termékek/muanyag/kck.png";


export default function Plastic() {

    


  return (
    <>
    
      <div className="container my-5">
        <div className="row">
          <div className="col">
            <h1 className="text-center">Műanyag termékek</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">CLASSIC AJTÓPANELEK</h2>
          </div>
          <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
            <div class="col">
              <div class="card prod">
                <img src={m_pic1} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Velebit tömör</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic2} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Velebit-3</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic3} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Lug tömör</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic4} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Lug DPK</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic5} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Lug PFB</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic6} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Mikanovci SB-BL-2</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic7} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Mikanovci-FAB 2</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic8} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Nijemci</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic9} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Vinkovci</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic10} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Vukovar</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">STANDARD PLUS AJTÓPANELEK</h2>
          </div>
          <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
            <div class="col">
              <div class="card prod">
                <img src={m_pic11} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Sara</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic12} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Tara</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic13} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Mara</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic14} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Lara</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic15} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Zora</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic16} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Mara+</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2 className="text-center mt-5 mb-3">PRÉMIUM HOME AJTÓPANEL</h2>
            </div>
            <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
              <div class="col">
                <div class="card prod">
                  <img src={m_pic17} class="card-img-top img-fluid p-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">ARTE AJTÓPANELEK</h2>
          </div>
          <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
            <div class="col">
              <div class="card prod">
                <img src={m_pic18} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Monet tömör</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic19} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Vermeer DPL</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic20} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Botticelli DPV</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic21} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Dali DPZ</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic22} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Renoir PFL3</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic23} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">Degas DPR</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">FUTURE AJTÓPANELEK</h2>
          </div>
          <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
            <div class="col">
              <div class="card prod">
                <img src={m_pic24} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">ZUM</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic25} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">BIO</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic26} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">PSU</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic27} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">GAL</h5>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={m_pic28} class="card-img-top img-fluid p-4" />
                <div class="card-body">
                  <h5 class="card-title">JAN</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2 className="text-center mt-5 mb-3">
                SMART REJTETT SZÁRNYAS PANELEK
              </h2>
            </div>
            <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
              <div class="col">
                <div class="card prod">
                  <img src={m_pic29} class="card-img-top img-fluid p-4" />
                  <div class="card-body">
                    <h5 class="card-title">SMART-1</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card prod">
                  <img src={m_pic30} class="card-img-top img-fluid p-4" />
                  <div class="card-body">
                    <h5 class="card-title">SMART-3</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card prod">
                  <img src={m_pic31} class="card-img-top img-fluid p-4" />
                  <div class="card-body">
                    <h5 class="card-title">SMART-5</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card prod">
                  <img src={m_pic32} class="card-img-top img-fluid p-4" />
                  <div class="card-body">
                    <h5 class="card-title">SMART-6</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card prod">
                  <img src={m_pic33} class="card-img-top img-fluid p-4" />
                  <div class="card-body">
                    <h5 class="card-title">SMART-7</h5>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card prod">
                  <img src={m_pic34} class="card-img-top img-fluid p-4" />
                  <div class="card-body">
                    <h5 class="card-title">SMART-10</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2 className="text-center mt-5 mb-3">
                  BASIC REJTETT SZÁRNYAS PANELEK
                </h2>
              </div>
              <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
                <div class="col">
                  <div class="card prod">
                    <img src={m_pic35} class="card-img-top img-fluid p-4" />
                    <div class="card-body">
                      <h5 class="card-title">BASIC-1</h5>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card prod">
                    <img src={m_pic36} class="card-img-top img-fluid p-4" />
                    <div class="card-body">
                      <h5 class="card-title">BASIC-3</h5>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card prod">
                    <img src={m_pic37} class="card-img-top img-fluid p-4" />
                    <div class="card-body">
                      <h5 class="card-title">BASIC-5</h5>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card prod">
                    <img src={m_pic38} class="card-img-top img-fluid p-4" />
                    <div class="card-body">
                      <h5 class="card-title">BASIC-7</h5>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card prod">
                    <img src={m_pic39} class="card-img-top img-fluid p-4" />
                    <div class="card-body">
                      <h5 class="card-title">BASIC-9</h5>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card prod">
                    <img src={m_pic40} class="card-img-top img-fluid p-4" />
                    <div class="card-body">
                      <h5 class="card-title">BASIC-10</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h2 className="text-center mt-5 mb-3">
                    ELITE REJTETT SZÁRNYAS PANELEK
                  </h2>
                </div>
                <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic41} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Seine</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic42} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Loire</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic43} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Tallin</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic44} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Bologna</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic45} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Hamburg</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic46} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Milano</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic47} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Minsk</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic48} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Zürich</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic49} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Napoli</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic50} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Aalborg</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic51} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Sion</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h2 className="text-center mt-5 mb-3">
                    SZÁRNYRA SZERELHETŐ FOGANYTÚK
                  </h2>
                </div>
                <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
                  <div class="col">
                    <div class="card prod  h-100">
                      <img src={m_pic52} class="card-img-top img-fluid" />
                      <div class="card-body">
                        <h5 class="card-title">Kör keresztmetszetü 30 mm</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod">
                      <img src={m_pic53} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">
                          Szögletes keresztmetszetü 40x15 mm
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card h-100 prod">
                      <img src={m_pic54} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Hoppe Liverpool félkilincs 40x15 mm</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card prod h-100">
                      <img src={m_pic55} class="card-img-top img-fluid p-4" />
                      <div class="card-body">
                        <h5 class="card-title">Rozsdamentes ovál rozetta külső</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h2 className="text-center mt-5 mb-3">
                      PANELRA SZERELHETŐ FOGANYTÚK
                    </h2>
                  </div>
                  <div class="row row-cols-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
                    <div class="col">
                      <div class="card h-100 prod">
                        <img src={m_pic56} class="card-img-top img-fluid p-4" />
                        <div class="card-body">
                          <h5 class="card-title">DOS 50x8 mm rozsdamentes polírozott</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card h-100 prod">
                        <img src={m_pic57} class="card-img-top img-fluid p-4" />
                        <div class="card-body">
                          <h5 class="card-title">KCR 30x30 mm rozsdamentes szálcsiszolt</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card h-100 prod">
                        <img src={m_pic58} class="card-img-top img-fluid p-4" />
                        <div class="card-body">
                          <h5 class="card-title">OCR 30x30 mm rozsdamentes szálcsiszolt</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card h-100 prod">
                        <img src={m_pic59} class="card-img-top img-fluid p-4" />
                        <div class="card-body">
                          <h5 class="card-title">KCK 40x40 mm rozsdamentes szálcsiszolt</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
