import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Alert } from "react-bootstrap";

export default function ContactUs() {
  const form = useRef();
  const [showAlert, setShowAlert] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tmju03e",
        "template_jpd9puu",
        form.current,
        "aurx6H6PsmUE5Qdf6"
      )
      .then((result) => {
        console.log(result.text);
        setShowAlert(true);
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  // Use 'useEffect' to automatically hide the alert after 5 seconds
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5 seconds in milliseconds

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <div>
      <div className="cocontainer my-5">
        <div className="row">
            <div className="col">
                <h1 className="text-center">ÁRAJÁNLATOT SZERETNE?</h1>
                <h2 className="text-center mb-5">Vegye fel velünk a kapcsolatot!</h2>
                
            </div>

        </div>
        <div className="row">
          <div className="col-10 col-lg-8 col-xl-6 col-xxl-4 mx-auto">
            <form ref={form} onSubmit={sendEmail} className="my-3 mx-auto p-5 bder">
              <div className="mb-3">
                <label htmlFor="user_name" className="form-label bold">
                  Név
                </label>
                <input
                  type="text"
                  name="user_name"
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="user_email" className="form-label bold">
                  Email
                </label>
                <input
                  type="email"
                  name="user_email"
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label bold">
                  Üzenet
                </label>
                <textarea name="message" className="form-control" required rows={8} />
              </div>
              <div className="text-center">
              <button type="submit" className="btn btn-danger px-5 py-3">
               Küldés
              </button>
              </div>
              
            </form>
          </div>
          
        </div>
       <div className="container my-5">
        <p className="text-center">
        Az Ön által megadott személyes adatokat kizárólag a kapcsolatfelvétel és válaszadás céljából használjuk. Az űrlap kitöltésével Ön hozzájárul az adatok ezen célokra történő felhasználásához.
        </p>
       </div>
      </div>
      <Alert
        show={showAlert}
        variant="success"
        onClose={() => setShowAlert(false)}
        dismissible
        className="w-100 text-center"
        style={{position: 'fixed', top: '10rem'}}
      >
       <h2>Üzenet elküldve</h2>
      </Alert>
    </div>
  );
}
