import React from "react";
import "./styles/page4_style.css";
import "../../../global_style.css";

export default function AboutUs() {
  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col">
            <h1 className="text-center mb-3">RÓLUNK</h1>
          </div>
        </div>
        <div className="row">
          <p>
            <b> Üdvözlöm,</b> Csontos Csaba vagyok, egyéni vállalkozó, három
            gyermekes édesapa és szerető férj. Hiszem, hogy otthonunk biztonsága
            és melege meghatározó az ember és gyermekei életében. Nyílászáró
            gyártással és beépítéssel foglalkozom közel
            <b> 20 éve</b>
            ,és ez inspirált vállalkozásom kibővítésére, hogy ne csak
            gyártással, hanem helyszíni felméréssel és beépítéssel is
            foglalkozzak.
          </p>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Cégünk, mint családi vállalkozás alakult. A prémium nyílászárók
              teljes skáláját, legyen az forma vagy szín, az ön egyedi
              igényeihez igazítva állítjuk elő. Ezzel növeljük otthona értékét
              és egyedi stílusosságát. Legyen az műanyag vagy fa nyílászáró,
              klasszikus vagy modern stílusban, nálunk minden álma megvalósul, a
              felméréstől a beépítésig.
            </p>
          </div>
        </div>
       <div className="row">
        <div className="col">
        <img
          className="my-5 w-100"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Family_Portrait.jpg/1200px-Family_Portrait.jpg"
        ></img>
        </div>
       </div>
      </div>
    </>
  );
}
