import React from "react";
import "./styles/page3_style.css";
import Plastic from "./plastic";
import Wooden from "./wooden";
import Color from "./color";
import { Link } from "react-router-dom";

export default function Products() {
  return (
    <div className="container my-5 text-center">
      <div className="row">
        <div className="col">
          <h1 className="text-center mb-5">Termékek</h1>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-12 col-lg-6">
          <div class="card prod big h-100">
            <img
              class="card-img-top"
              src="https://d2rxt25e475whq.cloudfront.net/wp-content/uploads/2023/07/25230625/AdobeStock_502187370-1-scaled.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h3 class="card-title">FA TERMÉKEK</h3>
              <p class="card-text">
                A fa természetességével az otthon melegért!
              </p>
              <Link to="/Products/Wooden" class="btn btn-danger">
               Tovább
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div class="card prod big h-100">
            <img
              class="card-img-top"
              src="https://media.istockphoto.com/id/464792862/photo/lodge.jpg?s=612x612&w=0&k=20&c=FiVhUkLtbR3rH5aSiqiUQrXnMfsC6-qJjqYyrUmT13w="
              alt="Card image cap"
            />
            <div class="card-body">
            <h3 class="card-title">MŰANYAG TERMÉKEK</h3>
              <p class="card-text">
                Végtelenszer újrahasznosítható termékek, a jövő záloga!
              </p>
              <Link to="/Products/Plastic" class="btn btn-danger">
                Tovább
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12">
          <div class="card prod big">
            <img
              class="card-img-top"
              src="https://cdn.mos.cms.futurecdn.net/YNgTPaPifRMyG5G7GXvgCH-1200-80.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h3 class="card-title">SZÍNEK</h3>
              <p class="card-text">
               A színvilág végtelen spalettájával!
              </p>
              <Link to="/Products/Color" class="btn btn-danger">
                Tovább
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/*
<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png"
              class="card-img-top img-fluid"
              alt="Hollywood Sign on The Hill"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                longer cardlonger cardlonger cardlonger cardlonger cardlonger
                cardlonger card
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://i.pinimg.com/474x/dd/4d/cf/dd4dcfdcceebf86608f79fdc60ea18e3.jpg"
              class="card-img-top img-fluid"
              alt="Palm Springs Road"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://upload.wikimedia.org/wikipedia/hu/f/fd/Pikachu_thunderbolt.jpg"
              class="card-img-top img-fluid"
              alt="Los Angeles Skyscrapers"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                facilisis blandit nulla et condimentum. Ut lacinia,
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://www.thatvideogameblog.com/wp-content/uploads/2023/01/pikachu-mandela-effect-header.jpg"
              class="card-img-top img-fluid"
              alt="Skyscrapers"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                valamiPikachuPikachuPikachuPikachuPikachuasdasdasdasd
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png"
              class="card-img-top img-fluid"
              alt="Hollywood Sign on The Hill"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                longer cardlonger cardlonger cardlonger cardlonger cardlonger
                cardlonger card
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://i.pinimg.com/474x/dd/4d/cf/dd4dcfdcceebf86608f79fdc60ea18e3.jpg"
              class="card-img-top img-fluid"
              alt="Palm Springs Road"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://upload.wikimedia.org/wikipedia/hu/f/fd/Pikachu_thunderbolt.jpg"
              class="card-img-top img-fluid"
              alt="Los Angeles Skyscrapers"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                facilisis blandit nulla et condimentum. Ut lacinia,
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://www.thatvideogameblog.com/wp-content/uploads/2023/01/pikachu-mandela-effect-header.jpg"
              class="card-img-top img-fluid"
              alt="Skyscrapers"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                valamiPikachuPikachuPikachuPikachuPikachuasdasdasdasd
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png"
              class="card-img-top img-fluid"
              alt="Hollywood Sign on The Hill"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                longer cardlonger cardlonger cardlonger cardlonger cardlonger
                cardlonger card
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://i.pinimg.com/474x/dd/4d/cf/dd4dcfdcceebf86608f79fdc60ea18e3.jpg"
              class="card-img-top img-fluid"
              alt="Palm Springs Road"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://upload.wikimedia.org/wikipedia/hu/f/fd/Pikachu_thunderbolt.jpg"
              class="card-img-top img-fluid"
              alt="Los Angeles Skyscrapers"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                facilisis blandit nulla et condimentum. Ut lacinia,
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 prod">
            <img
              src="https://www.thatvideogameblog.com/wp-content/uploads/2023/01/pikachu-mandela-effect-header.jpg"
              class="card-img-top img-fluid"
              alt="Skyscrapers"
            />
            <div class="card-body">
              <h5 class="card-title">Pikachu</h5>
              <p class="card-text">
                valamiPikachuPikachuPikachuPikachuPikachuasdasdasdasd
              </p>
            </div>
          </div>
        </div>
      </div>

*/
