import React, { useState } from "react";
import rpic from "../../../assets/refs/r1.jpg";
import rpic2 from "../../../assets/refs/r2.jpg";
import rpic3 from "../../../assets/refs/r3.jpg";
import rpic4 from "../../../assets/refs/r4.jpg";
import rpic5 from "../../../assets/refs/r5.jpg";
import rpic6 from "../../../assets/refs/r6.jpg";
import rpic7 from "../../../assets/refs/r7.jpg";
import rpic8 from "../../../assets/refs/r8.jpg";
import rpic9 from "../../../assets/refs/r9.jpg";
import rpic10 from "../../../assets/refs/r10.jpg";
import rpic11 from "../../../assets/refs/r11.jpg";
import rpic12 from "../../../assets/refs/r12.jpg";
import rpic13 from "../../../assets/refs/r13.jpg";
import rpic14 from "../../../assets/refs/r14.jpg";
import rpic15 from "../../../assets/refs/r15.jpg";
import rpic16 from "../../../assets/refs/r16.jpg";
import rpic17 from "../../../assets/refs/r17.jpg";
import rpic18 from "../../../assets/refs/r18.jpg";
import rpic19 from "../../../assets/refs/r19.jpg";
import rpic20 from "../../../assets/refs/r20.jpg";
import rpic21 from "../../../assets/refs/r21.jpg";
import rpic22 from "../../../assets/refs/r22.jpg";
import rpic23 from "../../../assets/refs/r23.jpg";
import rpic24 from "../../../assets/refs/r24.jpg";
import rpic25 from "../../../assets/refs/r25.jpg";
import rpic26 from "../../../assets/refs/r26.jpg";
import rpic27 from "../../../assets/refs/r27.jpg";
import rpic28 from "../../../assets/refs/r28.jpg";
import rpic29 from "../../../assets/refs/r29.jpg";
import rpic30 from "../../../assets/refs/r30.jpg";
import rpic31 from "../../../assets/refs/r31.jpg";
import rpic32 from "../../../assets/refs/r32.jpg";
import rpic33 from "../../../assets/refs/r33.jpg";
import rpic34 from "../../../assets/refs/r34.jpg";
import rpic35 from "../../../assets/refs/r35.jpg";
import rpic36 from "../../../assets/refs/r36.jpg";
import rpic37 from "../../../assets/refs/r37.jpg";
import rpic38 from "../../../assets/refs/r38.jpg";
import rpic39 from "../../../assets/refs/r39.jpg";
import rpic40 from "../../../assets/refs/r40.jpg";
import rpic41 from "../../../assets/refs/r41.jpg";
import rpic42 from "../../../assets/refs/r42.jpg";
import rpic43 from "../../../assets/refs/r43.jpg";
import rpic44 from "../../../assets/refs/r44.jpg";
import rpic45 from "../../../assets/refs/r45.jpg";
import rpic46 from "../../../assets/refs/r46.jpg";
import rpic47 from "../../../assets/refs/r47.jpg";
import rpic48 from "../../../assets/refs/r48.jpg";
import rpic49 from "../../../assets/refs/r49.jpg";
import rpic50 from "../../../assets/refs/r50.jpg";
import rpic51 from "../../../assets/refs/r51.jpg";
import rpic52 from "../../../assets/refs/r52.jpg";
import rpic53 from "../../../assets/refs/r53.jpg";
import rpic54 from "../../../assets/refs/r54.jpg";
import rpic55 from "../../../assets/refs/r55.jpg";
import rpic56 from "../../../assets/refs/r56.jpg";
import rpic57 from "../../../assets/refs/r57.jpg";
import rpic58 from "../../../assets/refs/r58.jpg";
import rpic59 from "../../../assets/refs/r59.jpg";
import rpic60 from "../../../assets/refs/r60.jpg";
import rpic61 from "../../../assets/refs/r61.jpg";
import rpic62 from "../../../assets/refs/r62.jpg";
import rpic63 from "../../../assets/refs/r63.jpg";
import rpic64 from "../../../assets/refs/r64.jpg";
import rpic65 from "../../../assets/refs/r65.jpg";
import rpic66 from "../../../assets/refs/r66.jpg";
import rpic67 from "../../../assets/refs/r67.jpg";
import rpic68 from "../../../assets/refs/r68.jpg";
import rpic69 from "../../../assets/refs/r69.jpg";
import rpic70 from "../../../assets/refs/r70.jpg";
import rpic71 from "../../../assets/refs/r71.jpg";
import rpic72 from "../../../assets/refs/r72.jpg";
import rpic73 from "../../../assets/refs/r73.jpg";
import rpic74 from "../../../assets/refs/r74.jpg";
import rpic75 from "../../../assets/refs/r75.jpg";
import rpic76 from "../../../assets/refs/r76.jpg";
import rpic77 from "../../../assets/refs/r77.jpg";
import rpic78 from "../../../assets/refs/r78.jpg";
import rpic79 from "../../../assets/refs/r79.jpg";
import rpic80 from "../../../assets/refs/r80.jpg";
import rpic81 from "../../../assets/refs/r81.jpg";
import rpic82 from "../../../assets/refs/r82.jpg";
import rpic83 from "../../../assets/refs/r83.jpg";
import rpic84 from "../../../assets/refs/r84.jpg";
import rpic85 from "../../../assets/refs/r85.jpg";
import rpic86 from "../../../assets/refs/r86.jpg";
import rpic87 from "../../../assets/refs/r87.jpg";
import rpic88 from "../../../assets/refs/r88.jpg";
import rpic89 from "../../../assets/refs/r89.jpg";
import rpic90 from "../../../assets/refs/r90.jpg";
import rpic91 from "../../../assets/refs/r91.jpg";
import rpic92 from "../../../assets/refs/r92.jpg";
import rpic93 from "../../../assets/refs/r93.jpg";
import rpic94 from "../../../assets/refs/r94.jpg";
import rpic95 from "../../../assets/refs/r95.jpg";
import rpic96 from "../../../assets/refs/r96.jpg";
import rpic97 from "../../../assets/refs/r97.jpg";
import rpic98 from "../../../assets/refs/r98.jpg";
import rpic99 from "../../../assets/refs/r99.jpg";
import rpic100 from "../../../assets/refs/r100.jpg";
import rpic101 from "../../../assets/refs/r101.jpg";
import rpic102 from "../../../assets/refs/r102.jpg";
import rpic103 from "../../../assets/refs/r103.jpg";
import rpic104 from "../../../assets/refs/r104.jpg";
import rpic105 from "../../../assets/refs/r105.jpg";
import rpic106 from "../../../assets/refs/r106.jpg";
import rpic107 from "../../../assets/refs/r107.jpg";
import rpic108 from "../../../assets/refs/r108.jpg";
import rpic109 from "../../../assets/refs/r109.jpg";
import rpic110 from "../../../assets/refs/r110.jpg";
import rpic111 from "../../../assets/refs/r111.jpg";
import rpic112 from "../../../assets/refs/r112.jpg";
import rpic113 from "../../../assets/refs/r113.jpg";
import rpic114 from "../../../assets/refs/r114.jpg";
import rpic115 from "../../../assets/refs/r115.jpg";
import rpic116 from "../../../assets/refs/r116.jpg";
import rpic117 from "../../../assets/refs/r117.jpg";
import rpic118 from "../../../assets/refs/r118.jpg";
import rpic119 from "../../../assets/refs/r119.jpg";
import rpic120 from "../../../assets/refs/r120.jpg";
import rpic121 from "../../../assets/refs/r121.jpg";
import rpic122 from "../../../assets/refs/r122.jpg";
import rpic123 from "../../../assets/refs/r123.jpg";
import rpic124 from "../../../assets/refs/r124.jpg";
import rpic125 from "../../../assets/refs/r125.jpg";
import rpic126 from "../../../assets/refs/r126.jpg";
import rpic127 from "../../../assets/refs/r127.jpg";
import rpic128 from "../../../assets/refs/r128.jpg";
import rpic129 from "../../../assets/refs/r129.jpg";
import rpic130 from "../../../assets/refs/r130.jpg";
import rpic131 from "../../../assets/refs/r131.jpg";
import rpic132 from "../../../assets/refs/r132.jpg";
import rpic133 from "../../../assets/refs/r133.jpg";
import rpic134 from "../../../assets/refs/r134.jpg";
import rpic135 from "../../../assets/refs/r135.jpg";
import rpic136 from "../../../assets/refs/r136.jpg";
import rpic137 from "../../../assets/refs/r137.jpg";
import rpic138 from "../../../assets/refs/r138.jpg";
import rpic139 from "../../../assets/refs/r139.jpg";
import rpic140 from "../../../assets/refs/r140.jpg";
import rpic141 from "../../../assets/refs/r141.jpg";
import rpic142 from "../../../assets/refs/r142.jpg";
import rpic143 from "../../../assets/refs/r143.jpg";
import rpic144 from "../../../assets/refs/r144.jpg";
import rpic145 from "../../../assets/refs/r145.jpg";
import rpic146 from "../../../assets/refs/r146.jpg";
import rpic147 from "../../../assets/refs/r147.jpg";
import rpic148 from "../../../assets/refs/r148.jpg";
import rpic149 from "../../../assets/refs/r149.jpg";
import rpic150 from "../../../assets/refs/r150.jpg";
import rpic151 from "../../../assets/refs/r151.jpg";
import rpic152 from "../../../assets/refs/r152.jpg";
import rpic153 from "../../../assets/refs/r153.jpg";
import rpic154 from "../../../assets/refs/r154.jpg";
import rpic155 from "../../../assets/refs/r155.jpg";
import rpic156 from "../../../assets/refs/r156.jpg";
import rpic157 from "../../../assets/refs/r157.jpg";
import rpic158 from "../../../assets/refs/r158.jpg";
import rpic159 from "../../../assets/refs/r159.jpg";
import rpic160 from "../../../assets/refs/r160.jpg";
import rpic161 from "../../../assets/refs/r161.jpg";
import rpic162 from "../../../assets/refs/r162.jpg";
import rpic163 from "../../../assets/refs/r163.jpg";
import rpic164 from "../../../assets/refs/r164.jpg";
import rpic165 from "../../../assets/refs/r165.jpg";
import rpic166 from "../../../assets/refs/r166.jpg";
import rpic167 from "../../../assets/refs/r167.jpg";
import rpic168 from "../../../assets/refs/r168.jpg";
import rpic169 from "../../../assets/refs/r169.jpg";
import rpic170 from "../../../assets/refs/r170.jpg";
import rpic171 from "../../../assets/refs/r171.jpg";
import rpic172 from "../../../assets/refs/r172.jpg";
import rpic173 from "../../../assets/refs/r173.jpg";
import rpic174 from "../../../assets/refs/r174.jpg";
import rpic175 from "../../../assets/refs/r175.jpg";
import rpic176 from "../../../assets/refs/r176.jpg";
import rpic177 from "../../../assets/refs/r177.jpg";
import rpic178 from "../../../assets/refs/r178.jpg";
import rpic179 from "../../../assets/refs/r179.jpg";
import rpic180 from "../../../assets/refs/r180.jpg";
import rpic181 from "../../../assets/refs/r181.jpg";
import rpic182 from "../../../assets/refs/r182.jpg";
import rpic183 from "../../../assets/refs/r183.jpg";
import rpic184 from "../../../assets/refs/r184.jpg";
import rpic185 from "../../../assets/refs/r185.jpg";
import rpic186 from "../../../assets/refs/r186.jpg";
import rpic187 from "../../../assets/refs/r187.jpg";
import rpic188 from "../../../assets/refs/r188.jpg";
import "./styles/refs.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

export default function References() {
  const galleryImages = [
    {
      img: rpic,
    },
    {
      img: rpic2,
    },
    {
      img: rpic4,
    },
    {
      img: rpic5,
    },
    {
      img: rpic6,
    },
    {
      img: rpic7,
    },
    {
      img: rpic8,
    },
    {
      img: rpic9,
    },
    {
      img: rpic10,
    },
    {
      img: rpic11,
    },
    {
      img: rpic12,
    },
    {
      img: rpic13,
    },
    {
      img: rpic14,
    },
    {
      img: rpic15,
    },
    {
      img: rpic16,
    },
    {
      img: rpic17,
    },
    {
      img: rpic18,
    },
    {
      img: rpic19,
    },
    {
      img: rpic20,
    },
    {
      img: rpic21,
    },
    {
      img: rpic22,
    },
    {
      img: rpic23,
    },
    {
      img: rpic24,
    },
    {
      img: rpic25,
    },
    {
      img: rpic26,
    },
    {
      img: rpic27,
    },
    {
      img: rpic28,
    },
    {
      img: rpic29,
    },
    {
      img: rpic30,
    },
    {
      img: rpic31,
    },
    {
      img: rpic32,
    },
    {
      img: rpic33,
    },
    {
      img: rpic34,
    },
    {
      img: rpic35,
    },
    {
      img: rpic36,
    },
    {
      img: rpic37,
    },
    {
      img: rpic38,
    },
    {
      img: rpic39,
    },
    {
      img: rpic40,
    },
    {
      img: rpic41,
    },
    {
      img: rpic42,
    },
    {
      img: rpic43,
    },
    {
      img: rpic44,
    },
    {
      img: rpic45,
    },
    {
      img: rpic46,
    },
    {
      img: rpic47,
    },
    {
      img: rpic48,
    },
    {
      img: rpic49,
    },
    {
      img: rpic50,
    },
    {
      img: rpic51,
    },
    {
      img: rpic52,
    },
    {
      img: rpic53,
    },
    {
      img: rpic54,
    },
    {
      img: rpic55,
    },
    {
      img: rpic56,
    },
    {
      img: rpic57,
    },
    {
      img: rpic58,
    },
    {
      img: rpic59,
    },
    {
      img: rpic60,
    },
    {
      img: rpic61,
    },
    {
      img: rpic62,
    },
    {
      img: rpic63,
    },
    {
      img: rpic64,
    },
    {
      img: rpic65,
    },
    {
      img: rpic66,
    },
    {
      img: rpic67,
    },
    {
      img: rpic68,
    },
    {
      img: rpic69,
    },
    {
      img: rpic70,
    },
    {
      img: rpic71,
    },
    {
      img: rpic72,
    },
    {
      img: rpic73,
    },
    {
      img: rpic74,
    },
    {
      img: rpic75,
    },
    {
      img: rpic76,
    },
    {
      img: rpic77,
    },
    {
      img: rpic78,
    },
    {
      img: rpic79,
    },
    {
      img: rpic80,
    },
    {
      img: rpic81,
    },
    {
      img: rpic82,
    },
    {
      img: rpic83,
    },
    {
      img: rpic84,
    },
    {
      img: rpic85,
    },
    {
      img: rpic86,
    },
    {
      img: rpic87,
    },
    {
      img: rpic88,
    },
    {
      img: rpic89,
    },
    {
      img: rpic90,
    },
    {
      img: rpic91,
    },
    {
      img: rpic92,
    },
    {
      img: rpic93,
    },
    {
      img: rpic94,
    },
    {
      img: rpic95,
    },
    {
      img: rpic96,
    },
    {
      img: rpic97,
    },
    {
      img: rpic98,
    },
    {
      img: rpic99,
    },
    {
      img: rpic100,
    },
    {
      img: rpic101,
    },
    {
      img: rpic102,
    },
    {
      img: rpic103,
    },
    {
      img: rpic104,
    },
    {
      img: rpic105,
    },
    {
      img: rpic106,
    },
    {
      img: rpic107,
    },
    {
      img: rpic108,
    },
    {
      img: rpic109,
    },
    {
      img: rpic110,
    },
    {
      img: rpic111,
    },
    {
      img: rpic112,
    },
    {
      img: rpic113,
    },
    {
      img: rpic114,
    },
    {
      img: rpic115,
    },
    {
      img: rpic116,
    },
    {
      img: rpic117,
    },
    {
      img: rpic118,
    },
    {
      img: rpic119,
    },
    {
      img: rpic120,
    },
    {
      img: rpic121,
    },
    {
      img: rpic122,
    },
    {
      img: rpic123,
    },
    {
      img: rpic124,
    },
    {
      img: rpic125,
    },
    {
      img: rpic126,
    },
    {
      img: rpic127,
    },
    {
      img: rpic128,
    },
    {
      img: rpic129,
    },
    {
      img: rpic130,
    },
    {
      img: rpic131,
    },
    {
      img: rpic132,
    },
    {
      img: rpic133,
    },
    {
      img: rpic134,
    },
    {
      img: rpic135,
    },
    {
      img: rpic136,
    },
    {
      img: rpic137,
    },
    {
      img: rpic138,
    },
    {
      img: rpic139,
    },
    {
      img: rpic140,
    },
    {
      img: rpic141,
    },
    {
      img: rpic142,
    },
    {
      img: rpic143,
    },
    {
      img: rpic144,
    },
    {
      img: rpic145,
    },
    {
      img: rpic146,
    },
    {
      img: rpic147,
    },
    {
      img: rpic148,
    },
    {
      img: rpic149,
    },
    {
      img: rpic150,
    },
    {
      img: rpic151,
    },
    {
      img: rpic152,
    },
    {
      img: rpic153,
    },
    {
      img: rpic154,
    },
    {
      img: rpic155,
    },
    {
      img: rpic156,
    },
    {
      img: rpic157,
    },
    {
      img: rpic158,
    },
    {
      img: rpic159,
    },
    {
      img: rpic160,
    },
    {
      img: rpic161,
    },
    {
      img: rpic162,
    },
    {
      img: rpic163,
    },
    {
      img: rpic164,
    },
    {
      img: rpic165,
    },
    {
      img: rpic166,
    },
    {
      img: rpic167,
    },
    {
      img: rpic168,
    },
    {
      img: rpic169,
    },
    {
      img: rpic170,
    },
    {
      img: rpic171,
    },
    {
      img: rpic172,
    },
    {
      img: rpic173,
    },
    {
      img: rpic174,
    },
    {
      img: rpic175,
    },
    {
      img: rpic176,
    },
    {
      img: rpic177,
    },
    {
      img: rpic178,
    },
    {
      img: rpic179,
    },
    {
      img: rpic180,
    },
    {
      img: rpic181,
    },
    {
      img: rpic182,
    },
    {
      img: rpic183,
    },
    {
      img: rpic184,
    },
    {
      img: rpic185,
    },
    {
      img: rpic186,
    },
    {
      img: rpic187,
    },
    {
      img: rpic188,
    },
   
  ];
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col">
            <h1 className="text-center mb-3">Referenciák</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
          <div>
            {openModal && (
              <div className="sliderWrap">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="btnClose"
                  onClick={handleCloseModal}
                />
                <FontAwesomeIcon
                  icon={faCircleChevronLeft}
                  className="btnPrev"
                  onClick={prevSlide}
                />
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  className="btnNext"
                  onClick={nextSlide}
                />
                <div className="fullScreenImage">
                  <img src={galleryImages[slideNumber].img} alt="" />
                </div>
              </div>
            )}

            {/* <br />
Current slide number:  {slideNumber}
<br />
Total Slides: {galleryImages.length}
<br /><br /> */}

            <div className="galleryWrap">
              {galleryImages &&
                galleryImages.map((slide, index) => {
                  return (
                    <div
                      className="single"
                      key={index}
                      onClick={() => handleOpenModal(index)}
                    >
                      <img src={slide.img} alt="" />
                    </div>
                  );
                })}
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
