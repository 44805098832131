import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./styles/page2_style.css";
import Card from "react-bootstrap/Card";

export default function Services() {
  return (
    <>
      <div className="container my-5 text-center">
        <div className="row">
          <div className="col">
            <h1 className="mb-5">Szolgáltatások</h1>
          </div>
        </div>
      </div>
      <div className="area">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 g-4 my-3">
            <div class="col order-2 text-center d-flex align-items-center justify-content-center">
              <img
                className="w-100"
                src="https://daidalos.hu/wp-content/uploads/2019/06/szolgaltatasok-fejlec.jpg"
              ></img>
            </div>

            <div class="col">
              <h2 className="text-center mb-4">Gyártás, Beépítés</h2>
              <p>
                Cégünk vállalja nyílászárók teljes kivitelezését, felméréstől a
                beépítésig hagyományos , illetve extra hőszigetelő
                elhelyezéssel.
              </p>
              <p>
                Nem ismerünk határokat sem kivitelezésben, sem megvalósításban.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div class="card mt-4 crd">
            <div class="card-header">Szlogenünk:</div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p>
                  Minden munka amit elvégzel az a te aláírásod és amilyen az
                  aláírásod olyan ember vagy te magad is
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className="area">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 g-4 my-3 ">
            <div class="col order-2">
              <h2 className="text-center mb-4">
                Bontás, Beépítés, Redőnyszerelés
              </h2>
              <p>
                Legyen az új ház vagy akár egy felújítás, mi minden kihívásra
                készen állunk.
              </p>
            </div>
            <div class="col order-1 text-center d-flex align-items-center justify-content-center">
              <img
                className="w-100"
                src="https://www.ablakbudapest.hu/wp-content/uploads/2019/02/15-54ad3bb0ba.jpg"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3">
        <div className="row">
          <div class="col">
            <span className="red">
              <p>
                6 év garancia (2 év jótállás és 4 év szavatosság) minimum 10 év
                időjárásálló garancia.
              </p>
            </span>
          </div>
        </div>
      </div>
      
      <div className="area">
        <div className="container">
          <div className="row g-4 my-3 ">
            <div class="col">
              <h2 className="text-center mb-4">
                Vásárlás utáni szerviz, garancia
              </h2>
              <p>
                Vásárlás után sem hagyjuk magára a tisztelt megrendelőinket. Bármilyen kérdésre szívesen válaszolunk, vagy ha a használat során további információkra van szüksége állunk rendelkezésre.
                </p>
                <p>
                Amennyiben segítségre szorulna, forduljon ügyintézőnkhöz!
                Teljeskörű szerkezeti és működési garancia a szakszerűen beépített és rendeltetésszerűen használt nyílászáró szerkezetekre, az ide vonatkozó rendeletek szerint 6 év szavatosság, ezen belül 1 év teljes körű garancia érvényes.
                </p>
                <p>
                A megrendelő által közvetlenül megbízott más személlyel vagy céggel végeztetett nyílászáró beszerelésénél, a működési garancia nem jár a nyílászárókra. Ez esetben a beépítésből eredő esetleges működési probléma megoldása külön szervizköltség, mely az ügyfelet terheli.
                </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
