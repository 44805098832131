import React from "react";

export default function Aszf() {
  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col">
            <h1 className="text-center">ÁSZF</h1>
          </div>
        </div>

        <div className="row">
          <div className="col mb-5">
            Általános Szállítási és Fizetési Feltételek Kérjük, hogy jelen
            Általános Szállítási és Fizetési Feltételeinket (ÁSZF) megrendelés
            előtt gondosan tanulmányozza át, mert nem áll módunkban
            Megrendelőinkkel ettől eltérő feltételekkel megállapodni! Áraink: Az
            Árajánlatunk, majd az az alapján, a végleges műszaki paraméterekkel
            készített Megrendelés visszaigazolásunk a műszaki tartalomban
            meghatározott és a pozícióknál feltüntetett paraméterekre
            vonatkozik. Műszaki félreértésből adódó hibákért felelősséget nem
            vállalunk. Amennyiben Árajánlatunk/Megrendelés visszaigazolásunk
            erre külön nem tér ki, nem tartalmazza a szállítás, a bontás, a
            beépítés, a takarólécezés, párkányozás, az árnyékolástechnikai
            kiegészítők felszerelésének, a takaró- és saroklécek, díszlécek, fa
            könyöklők, fa rovarhálók, zsalugáterek, spaletták, alu vagy műanyag
            redőnyök és rovarhálók, az alapárastól eltérő kilincsek, az extra
            kiegészítők és tartozékok költségét. Amennyiben az Árajánlat erre
            külön nem tér ki, a felületkezelt szerkezetek árai az alap
            színkínálatban szereplő színekre vonatkoznak, a nyílászárók külső és
            belső felületén azonos színben. Az alap kínálattól eltérő színek
            (egyedi színbeállítás, RAL színek, kétszínű felületkezelés, beltéri
            ajtók antikolt felületkezelése) felár ellenében rendelhetők. Az
            Árajánlatban/Megrendelés visszaigazolásban szereplő műszaki tartalom
            bárminemű módosítása (pl: mennyiség, méret, kivitelbeli változások,
            extrák) az árak változását vonhatja maga után. Megrendelés
            érvényessége: Megrendelésnek csak a Megrendelő által aláírt
            Megrendelés visszaigazolás tekinthető, melyen a Megrendelő
            feltünteti a pontos számlázási és postázási nevet és címet (cég
            esetén az adószámot is). A megrendelő az aláírást követően
            beszkennelve e-mailben, esetleg postán is eljuttathatja a Gyártó
            részére. A megrendelés szóbeli módosítását csak abban az esetben
            fogadjuk el, ha utána a módosított Megrendelés visszaigazolásunkat a
            Megrendelő aláírja. A módosítás plusz költségeket róhat a
            Megrendelőre! Fizetési feltételek: A Megrendelő a Megrendelés
            visszaigazolás aláírásával kötelezettséget vállal az elkészített
            termékek átvételére és az ellenérték teljes összegének kifizetésére.
            Viszonteladó partnereink esetén a fizetési feltételek: Viszonteladói
            szerződésben meghatározottak szerint. A nyílászárók megrendelésekor
            50% előleg esedékes, mely elengedhetetlen feltétele a gyártás
            megkezdésének. Az előleg banki átutalással történő teljesítése
            esetén a Gyártó Díjbekérőt állít ki Megrendelő részére, majd az
            összeg bankszámlánkra történő beérkezését követően állítjuk ki az
            előlegről szóló számlát és juttatjuk el a Megrendelőnek postai úton.
            Készpénzes fizetés esetén készpénzes számlát állítunk ki az
            előlegről. Készre jelentéskor (szállítást megelőzően) a nyílászáró
            szerkezetek és kiegészítőikről végszámlát állítunk ki, melyet banki
            átutalással kérünk teljesíteni, vagy készpénzben. A megrendelt
            szolgáltatásaink (mint pl. szállítás, bontás, beépítés,
            takarólécezés, párkányozás) díját a munka elvégzését követően
            készpénzben kérjük teljesíteni, számla ellenében. Telephelyünkön
            történő átvétel esetén készpénzben, vagy előre utalással is
            teljesíthet. A teljes összeg kiegyenlítéséig a termék a Gyártó
            kizárólagos tulajdonát képezi. Felmérés: Amennyiben Megrendelő
            megrendeli a beépítési szolgáltatást, úgy a Gyártó helyszíni
            felmérést biztosít a gyártás megkezdése előtt. A felmérést és a
            beépítést ugyanazon személy végzi. A felméréskor szükséges a
            Megrendelő, vagy annak műszaki döntésre jogosult megbízottjának
            személyes jelenléte. A Megrendelés visszaigazolás a felmérés alapján
            készül. A felmérés, majd az azt követő visszaigazolás a gyakorlat
            szerint kb.2 hetet vesz igénybe. Kérjük ennek figyelembevételét! A
            felmérés feltétele a végleges padlószint feletti 1,00 m-es
            szintvonal és a vakolt vagy vakolás nélküli kávák megléte.
            Amennyiben Megrendelő nem igényel beépítést, úgy a nyílászárók
            méreteit a Megrendelő biztosítja a Gyártó részére, s így a Gyártó
            nem tartozik felelősséggel a megadott méretek helyességért. Gyártási
            határidő: A várható gyártási határidő az előleg és az aláírt
            megrendelés beérkezése után, a végleges egyeztetéstől számított:
            natúr termékek esetén 4-6 hét, festettek esetén 6-12 hét, műanyag nyílászáró esetén 6-8 hét, illetve
            a Megrendelés visszaigazoláson feltüntetett határidő, ami csak abban
            az esetben tartható, ha az aláírt Megrendelés visszaigazolás, majd
            az előleg beérkezik 5 munkanapon belül, illetve egyedi megállapodás
            alapján. Soron kívül igényelt gyártás esetén Gyártó felárat számít
            fel. A gyártási határidő után 5 munkanapon belül történő szállítás
            még időben történő teljesítésnek minősül. A megrendelés
            visszaigazolás aláírását követően a gyártás közbeni bárminemű
            módosítás a gyártási határidő meghosszabbítását vonhatja maga után!
            A szállítás és szerelés pontos napjáról az azt megelőző napokban
            Gyártó szóban vagy írásban értesíti ügyfeleit. A Megrendelő elismeri
            a Gyártó előteljesítési jogát. Kiszállítás: A szállítás során
            keletkezett sérülésekért, károkért Gyártó csak az általa elvégzett
            szállítás esetén vállal felellőséget. A szállítási szolgáltatást és
            annak díját az Árajánlatunk/Megrendelés visszaigazolásunk
            tartalmazza, amennyiben az ügyfél ezt igényelte. A szállítást a
            Megrendelő is megoldhatja az általa biztosított gépjárművel, saját
            felelősségére és költségére. Viszonteladó partnereink esetén a
            szállítási feltételek: Viszonteladói szerződésben meghatározottak
            szerint. Beépítés: Amennyiben Megrendelő igényelte a beépítést, vagy
            bontás-beépítést, úgy e szolgáltatásokat és díjaikat az
            Árajánlatunk/Megrendelés visszaigazolásunk tartalmazza. A Gyártó a
            beépítés kezdő napját a szállítás napjára igazítja, de ezen időpont
            csak akkor tartható, ha Megrendelő biztosítja az ehhez szükséges
            feltételeket (falazási munkák, aljzatbetonozás megléte, belső
            vakolási munkák elkészülte). A beépítés díja tartalmazza a rögzítő
            anyagokat (purhab, tokrögzítő csavarok, stb.), de nem tartalmazza a
            falnyílás esetleges vésését (nyílászáróhoz igazítását), a bontásnál
            keletkező törmelék és bontott anyag elszállítását, állvány, daru
            vagy emelő biztosítását, lécezést, párkányozást (kivéve, ha e
            visszaigazolásban ezen szolgáltatások és díjaik szerepelnek).A
            beépítést követő javító jellegű kőműves és egyéb szakipari munkák
            megoldása nem a cégünk, vagy nem a cégünk által megbízott beépítést
            végző alvállalkozó feladata. A beépítés a falazási munkákat
            követően, az aljzatbeton meglétét követően, a belső vakolási munkák
            befejezése után történhet. A nyílászárók beépítésekor a belső
            vakolásnak és a végleges aljzatbetonnak mindenképpen készen kell
            lennie, a vakolatból felszabaduló építési nedvességnek el kell
            távoznia. Az épület nedvesség tartalma max. 40-45% lehet a beépített
            nyílászárók hibamentes működése érdekében (ebben az esetben a
            nyílászárók nedvességtartalma 10 - 11 %). Nagyobb pára esetén
            indokolt a napi négyszeri szellőztetés. Amennyiben az épületben a
            páratartalom ennél magasabb, úgy a nyílászáróknál méretbeli
            változásokra, vetemedésre kell számítani. A magas páratartalomból
            eredő hibák felelőssége és javítási költsége a Megrendelőt terheli.
            Garancia: Rendeltetésszerű használat esetén 6 év (2 év jótállás +4
            év szavatosság). Garanciális igény benyújtását a Gyártó csak teljes
            mértékben kiegyenlített számla esetében köteles elfogadni. A
            garancia nem terjed ki az elemi károkra és harmadik személy által
            okozott károkra (pl. kőműves, festő, helyreállító munkákból eredő
            hibák). Szakszerűtlen karbantartás, idegen eredetű javítás,
            beavatkozás, változás esetén a Gyártó mentesül a garancia alól.
            Minőségi kifogásait a Megrendelő minden esetben írásban köteles
            közölni. Minőségi kifogás esetén a Gyártó szakembert küld a hiba
            elhárítására. Jogtalan hiba bejelentés esetén a Megrendelő köteles a
            Gyártónak a kiszállítással kapcsolatos költségeit megtéríteni.
            Felületkezelés: A felületkezelés tartósságára csak akkor vonatkozik
            a garancia, ha annak évenkénti, ápoló készlettel történő
            karbantartása igazoltan megtörtént. A színkínálatunkban található
            lazúr színminták tájékoztató jellegűek. A fa természetes anyag,
            rajzolata egyszeri és megismételhetetlen, ezért felületkezelést
            követően a színmintához képest árnyalatnyi eltérést mutathat.
            Alapanyag: Borovi fenyő alapanyag választása esetén - a fafaj magas
            gyantatartalma miatt - a fokozott napsugárzásnak kitett helyeken a
            nyílászáró felületén nyomokban gyantafolyás jelentkezhet. Ez a
            nyílászáró minőségét nem befolyásolja, ezen okból felmerülő minőségi
            kifogást nem áll módunkban elfogadni. Meranti illetve bükkfából
            gyártott nyílászáróknál a színazonosságot nem tudjuk vállalni.
            Vörösfenyőből készült nyílászárók esetén az időjárási viszonyok
            változásával (különösen napsütés és hőmérsékletingadozás hatására)
            szemmel látható és kitapintható gyanta pontok jelenhetnek meg a fa
            felületén. A megjelent gyanta pontok eltávolíthatóak a felületről,
            az eltávolításhoz szükséges technológiáról, bővebb információkról
            tájékoztatót adunk. Toldásmentes fából gyártott nyílászárók esetén a
            3 méter feletti alkatrészeknél a toldásmentességet nem garantáljuk.
            Nyílászáróink CE minősítéssel rendelkeznek. Jogállás: A jelen
            ÁSZF-ben nem szabályozott kérdésekben a Polgári Törvénykönyv
            rendelkezései az irányadóak. Megrendelő a visszaigazolás aláírásával
            jogvita esetén elismeri a Bajai Városi Bíróság illetékességét.
            Minden a Gyártó által készített dokumentum (árajánlat, megrendelés
            visszaigazolás, stb.) az Ablacom.hu szellemi tulajdonát
            képezi, kizárólag a címzett nevére szól, tartalma részben vagy
            egészében való más irányú felhasználása, harmadik fél részére
            történő átadása tilos. Megrendelő a Megrendelés visszaigazolás
            aláírásával hozzájárul ahhoz, hogy az elkészült termékek fotóit a
            Gyártó marketing céljaira felhasználja.
          </div>
        </div>
      </div>
    </>
  );
}
