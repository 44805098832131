import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col">
          <h1 className="text-center mb-5">Kapcsolat</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-md-12">
          <iframe
            className="w-100"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d88187.91531535715!2d19.0006665!3d46.3123725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4742e2b089f834c1%3A0x400c4290c1e2080!2zU8O8a8O2c2QsIDYzNDY!5e0!3m2!1shu!2shu!4v1697653337698!5m2!1shu!2shu"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="col-xl-8 col-md-12">
          <section>
            <p className="mt-4 text-center">
              <strong> Elérhetőség</strong>
            </p>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="th-sm">Név</th>
                    <th className="th-sm">Telefon szám</th>
                    <th className="th-sm">Cím</th>
                    <th className="th-sm">Email</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Csontos Csaba</td>
                    <td>30/913-38-50</td>
                    <td>6346 Sükösd, Dózsa György 62</td>
                    <td>ablacom6346@gmail.com</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="text-center">
              <Link
                to="/PriceEstimates"
                className="btn btn-danger my-3 pt-3"
              >
                <h3>KÉRJEN ÁRAJÁNLATOT!</h3>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
