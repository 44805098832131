import React, { useState } from "react";
import "./nav_style.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";

export default function Navbar() {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const hideNav = () => {
    if (window.innerWidth <= 90 * 16) {
      setIsNavVisible(false);
    }
  };

  return (
    <div
      className={`primary-header flex ${
        isNavVisible ? "nav-visible" : "nav-hidden"
      }`}
    >
      <div>
        <NavLink to="/Home" onClick={hideNav} activeClassName="active">
          <img className="logo" src={logo} alt="Logo" />
        </NavLink>
      </div>
  
      <button
        className={`mobile-nav-toggle ${isNavVisible ? "nav-open" : ""}`}
        aria-controls="primary-navigation"
        onClick={toggleNav}
      ></button>
  
      <nav>
        <ul
          className={`primary-navigation flex ${isNavVisible ? "visible" : ""}`}
        >
          <li>
            <NavLink
              to="/Home"
              onClick={hideNav}
              className="nav_item p1"
              activeClassName="active"
            >
              Főoldal
            </NavLink>
          </li>
          <li>
            <NavLink to="/Services" onClick={hideNav} className="nav_item">
              Szolgáltatások
            </NavLink>
          </li>
          <li>
            <NavLink to="/Products" onClick={hideNav} className="nav_item">
              Termékek
            </NavLink>
          </li>
          <li>
            <NavLink to="/References" onClick={hideNav} className="nav_item">
              Referenciák
            </NavLink>
          </li>
          <li>
            <NavLink to="/AboutUs" onClick={hideNav} className="nav_item">
              Rólunk
            </NavLink>
          </li>
          <li>
            <NavLink to="/Contact" onClick={hideNav} className="nav_item">
              Kapcsolat
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
