import React from "react";

export default function Fixed_footer() {
  return (
    <div className="f_footer">
        <footer class="bg-danger text-center text-white">
          <div class="container py-1 ">
            <section class="">
              <form action="">
                <div class="row">
                  <div class="col">
                    <p class="pt-2">
                      <a className="phonenum" href="tel:+36309133850"> <strong>Hívjon most</strong></a>
                    </p>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </footer>
    </div>
  );
}
