import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="page-footer font-small pt-4">
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col mt-md-0 mt-3 mb-5">
            <h5 className="text-uppercase">ABLACOM</h5>
            <p>Garancia a minőségre!</p>
            <div className="col-2 text-center mx-auto">
              <img className="w-100" src={logo} alt="Logo 1" />
            </div>
          </div>
          <div className="col mt-md-0 mt-3 mb-5">
            <h5 className="text-uppercase">GYÁRTÓ</h5>
            <p>Időtálló termékek!</p>
            <div className="col-2 text-center mx-auto">
              <a target="_blank" href='https://veka.hu/'>
              <img src="https://veka.hu/wp-content/uploads/2019/05/VEKA-Logo.svg" alt="Logo 2" />
              </a>
            </div>
          </div>

          <div className="col mt-md-0 mt-3">
            <h5 className="text-uppercase">KÖVESSEN MINKET</h5>
            <p>ABLACOM</p>
            <div className="col-2 text-center mx-auto">
              <a target="_blank" href="https://www.facebook.com/profile.php?id=100063767193248">
                <img className="w-100" src="https://svgsilh.com/svg/667456.svg" alt="Facebook" />
              </a>
            </div>
          </div>

          <div className="col-md-3 mb-md-0 mb-3 text-center">
            <Link to="/PriceEstimates" onClick={scrollToTop} className="nav_item">
              KÉRJEN ÁRAJÁNLATOT!
            </Link>
            <Link to="/ASZF" onClick={scrollToTop} className="nav_item">
              ÁSZF
            </Link>
            <Link to="/Contact" onClick={scrollToTop} className="nav_item">
              KAPCSOLAT
            </Link>
            <Link to="/AboutUs" onClick={scrollToTop} className="nav_item">
              RÓLUNK
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">
        © 2023 Copyright: Ablacom
      </div>
    </footer>
  );
};

export default Footer;
