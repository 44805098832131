import React from "react";
import pic1 from "../../../assets/termékek/fa/bejarati/af2a-scaled.jpg";
import pic2 from "../../../assets/termékek/fa/bejarati/af3b-scaled.jpg";
import pic3 from "../../../assets/termékek/fa/bejarati/af3d-scaled.jpg";
import pic4 from "../../../assets/termékek/fa/bejarati/af5b-scaled.jpg";
import pic5 from "../../../assets/termékek/fa/bejarati/af5c-scaled.jpg";
import pic6 from "../../../assets/termékek/fa/bejarati/af6d-scaled.jpg";
import pic7 from "../../../assets/termékek/fa/bejarati/af7c-1-scaled.jpg";
import pic8 from "../../../assets/termékek/fa/bejarati/af8c-1-scaled.jpg";
import pic9 from "../../../assets/termékek/fa/bejarati/af13d-scaled.jpg";
import pic10 from "../../../assets/termékek/fa/bejarati/af14c-1-scaled.jpg";
import pic11 from "../../../assets/termékek/fa/bejarati/af18a-scaled.jpg";
import pic12 from "../../../assets/termékek/fa/bejarati/af19c-scaled.jpg";
import pic13 from "../../../assets/termékek/fa/bejarati/af20b-1-scaled.jpg";
import pic14 from "../../../assets/termékek/fa/bejarati/af26c-scaled.jpg";
import pic15 from "../../../assets/termékek/fa/bejarati/af53a-1-scaled.jpg";
import pic16 from "../../../assets/termékek/fa/bejarati/af55b-1-scaled.jpg";
import pic17 from "../../../assets/termékek/fa/bejarati/af80b-1-scaled.jpg";

import pic18 from "../../../assets/termékek/fa/belteri/af88a-scaled.jpg";
import pic19 from "../../../assets/termékek/fa/belteri/af88c-scaled.jpg";
import pic20 from "../../../assets/termékek/fa/belteri/af89b-scaled.jpg";
import pic21 from "../../../assets/termékek/fa/belteri/af93a-scaled.jpg";
import pic22 from "../../../assets/termékek/fa/belteri/af94b-scaled.jpg";
import pic23 from "../../../assets/termékek/fa/belteri/af97c-scaled.jpg";
import pic24 from "../../../assets/termékek/fa/belteri/af97d-scaled.jpg";
import pic25 from "../../../assets/termékek/fa/belteri/af101c-1-scaled.jpg";
import pic26 from "../../../assets/termékek/fa/belteri/af105b-scaled.jpg";
import pic27 from "../../../assets/termékek/fa/belteri/af109b-scaled.jpg";
import pic28 from "../../../assets/termékek/fa/belteri/af110a-scaled.jpg";
import pic29 from "../../../assets/termékek/fa/belteri/bf10b-1-scaled.jpg";
import pic30 from "../../../assets/termékek/fa/belteri/bf30a-1-scaled.jpg";
import pic31 from "../../../assets/termékek/fa/belteri/bf72a-scaled.jpg";
import pic32 from "../../../assets/termékek/fa/belteri/bf72b-scaled.jpg";
import pic33 from "../../../assets/termékek/fa/faalu_1.jpg";
import pic34 from "../../../assets/termékek/fa/faalu_2.jpg";
import pic35 from "../../../assets/termékek/fa/faalu_3.jpg";
import pic36 from "../../../assets/termékek/fa/faalu_4.jpg";
import pic37 from "../../../assets/termékek/fa/faalu_5.jpg";
import pic38 from "../../../assets/termékek/fa/faalu_6.jpg";

export default function Wooden() {
  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col">
            <h1 className="text-center">Fa termékek</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-5 mb-3">BEJÁRATI AJTÓK</h2>
          </div>
        </div>
        <div class="row row-cols-2 row-cols-md-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
          <div class="col">
            <div class="card prod">
              <img src={pic1} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af2a</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic2} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af3b</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic3} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af3d</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic4} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af5b</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic5} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af5c</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic6} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af6d</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic7} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af7c-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic8} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af8c-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic9} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af13d</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card  prod">
              <img src={pic10} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af14c-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic11} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af18a</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic12} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af19c</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic13} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af20b-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic14} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af26c</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic15} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af53a-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card  prod">
              <img src={pic16} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af55b-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic17} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af80b-1</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-center mt-5 mb-3">BELTÉRI AJTÓK</h2>
            </div>
          </div>
        </div>
        <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5">
          <div class="col">
            <div class="card prod">
              <img src={pic18} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af88a</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic19} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af88c</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic20} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af89b</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic21} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af93a</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic22} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af94b</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic23} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af97c</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic24} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af97d</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic25} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af101c</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic26} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af105b</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic27} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af109b</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic28} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">af110a</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic29} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">bf10b-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic30} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">bf30a-1</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic31} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">bf72a</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card prod">
              <img src={pic32} class="card-img-top img-fluid" />
              <div class="card-body">
                <h5 class="card-title">bf72b</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-center mt-5 mb-3">FA-ALU</h2>
              <p>
                Az Ablacom Innova 90 termékcsaládja tökéletes kombinációja a
                természetességnek és az időtállóságnak Belső oldalon a fa
                természetes szépsége határozza meg otthonunkat, mig kivülról a
                AULON aluminiumboritas élethosszig tartó különleges megjelenést
                nyújt. A 90 mm-es prafinak megfelelően 3 rétegű extra
                hőszigetelés (U=0.5 W/m2K) üvegezéssel és minőségi alapanyagok
                felhasználásával készül.
              </p>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3  row-cols-xl-4  g-4 mb-5 mt-2">
            <div class="col">
              <div class="card prod">
                <img src={pic33} class="card-img-top img-fluid h-100" />
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={pic34} class="card-img-top img-fluid h-100" />
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={pic35} class="card-img-top img-fluid h-100" />
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={pic36} class="card-img-top img-fluid h-100" />
              </div>
            </div>
            <div class="col">
              <div class="card prod">
                <img src={pic37} class="card-img-top img-fluid h-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
